import {
    CalendarIcon,
    ExternalLinkIcon,
    LocationMarkerIcon,
    ShoppingBagIcon,
    TicketIcon,
    UserGroupIcon,
    MusicNoteIcon,
} from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import axiosInstance from "../client";
import { Order } from "./Order";
import { Wien } from "./program/Wien";
import { Brno } from "./program/Brno";
import { Praha } from "./program/Praha";

export const Koncert = (props) => {
    const [remaining, setRemaining] = useState({
        praha: 100,
        brno: 80,
    });

    useEffect(
        () => {
            async function getCount() {
                try {
                    const res = await axiosInstance.get("wizard/get_count/");
                    if (res.data) {
                        setRemaining({
                            praha: remaining.praha - res.data.praha,
                            brno: remaining.brno - res.data.brno,
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            }
            getCount();
        },
        // eslint-disable-next-line
        []
    );

    const cities = [
        {
            date: "24. 3. 2024 v 18.00",
            alias: "wien",
            city: "Wien",
            location: "Galerie am Roten Hof",
            map: "https://maps.app.goo.gl/iQsUXZHTKT3R73XT8",
            tickets: false,
            program: Wien,
        },
        {
            date: "25. 3. 2024 v 18.30",
            alias: "brno",
            city: "Brno",
            map: "https://maps.app.goo.gl/7Sf4XcJc3YoEGwnNA",
            location: "Křišťálový sál, Stará radnice",
            capacity: remaining.brno,
            tickets: true,
            program: Brno,
        },
        {
            date: "26. 3. 2024 v 19.30",
            alias: "praha",
            city: "Praha",
            map: "https://goo.gl/maps/1ZAYU5mE4cFXkw3p8",
            location: "Atrium na Žižkově, Praha 3",
            capacity: remaining.praha,
            tickets: true,
            program: Praha,
        },
    ];

    function infoModal(item) {
        props.setModalContent({
            component: Order,
            params: {
                item: item,
            },
        });
        props.setModalIsOpen(true);
    }

    function programShow(item) {
        props.setModalContent({
            component: item.program,
        });
        props.setModalIsOpen(true);
    }

    return cities.map((item, itemIdx) => (
        <div key={itemIdx} className="pb-5 border-b space-y-2 mb-5">
            <div className="text-2xl font-bold">{item.city}</div>
            <div className="flex items-center space-x-2">
                <CalendarIcon className="w-6 h-6 text-red-600" />
                <div>{item.date}</div>
            </div>
            <div className="flex items-center space-x-2">
                <LocationMarkerIcon className="w-6 h-6 text-red-600" />
                <a
                    href={item.map}
                    title="Mapa"
                    className="underline hover:no-underline text-red-800"
                    target="_blank"
                    rel="noreferrer"
                >
                    {item.location}
                </a>
                <ExternalLinkIcon className="w-4 h-4 text-gray-400" />
            </div>
            <div className="flex items-center space-x-2">
                <MusicNoteIcon className="w-6 h-6 text-red-600" />
                <button
                    onClick={() => programShow(item)}
                    title="Program"
                    className="underline hover:no-underline text-red-800"
                >
                    Program
                </button>
            </div>
            {item.tickets ? (
                <div className="flex items-center space-x-2">
                    <UserGroupIcon className="w-6 h-6 text-red-600" />
                    <div>
                        Zbývá vstupenek:{" "}
                        <span className="font-bold">{item.capacity}</span>
                    </div>
                </div>
            ) : (
                <div className="hidden lg:block">&nbsp;</div>
            )}
            {item.tickets ? (
                <div className="flex items-center space-x-2">
                    <TicketIcon className="w-6 h-6 text-red-600" />
                    <div>
                        Cena vstupenky:{" "}
                        <span className="font-bold">300 Kč</span>
                    </div>
                </div>
            ) : (
                <div className="hidden lg:block">&nbsp;</div>
            )}
            <div className="flex items-center space-x-4">
                {item.tickets ? (
                    item.capacity > 0 && (
                        <button
                            onClick={() => infoModal(item)}
                            className="px-3 py-2 flex bg-blue-600 hover:bg-blue-700 text-white rounded-md my-3 space-x-2 w-32"
                        >
                            <ShoppingBagIcon className="w-6 h-6" />
                            <span>Objednat</span>
                        </button>
                    )
                ) : (
                    <span className="text-gray-500 px-3 py-2 border rounded-md  bg-gray-100 text-center my-3">
                        vstupné dobrovolné
                    </span>
                )}

                {/* <NavLink key={itemIdx} to={'koncert/' + item.alias}>
                      <button className="px-3 py-2 flex bg-gray-600 hover:bg-gray-700 text-white rounded-md my-3 space-x-2"><span>Více</span><ChevronRightIcon className="w-6 h-6" /></button>
                    </NavLink> */}
            </div>
        </div>
    ));
};
