import { ChevronLeftIcon } from "@heroicons/react/solid";
import { NavLink } from "react-router-dom";

import { Koncert } from "./Koncert";

export const Ingrid = (props) => {
    return (
        <>
            <div className="max-w-7xl mx-auto p-8 lg:p-10">
                <NavLink
                    to={"/"}
                    className="p-3  px-5 bg-gray-200 rounded-md inline-flex mb-5 items-center"
                >
                    <ChevronLeftIcon className="w-5 h-5" /> Zpět
                </NavLink>
                <div className="grid lg:grid-cols-2">
                    <div className=" prose prose-blue  ">
                        <h1>Ingrid Sotolářová</h1>
                        <p>
                            Narozena v&nbsp;Brně, Ingrid Sotolářová začala se
                            studiem klavíru v šesti letech. <br />
                            Během studii získala několik cen v národních
                            klavírních soutěžích:{" "}
                        </p>
                        <ul>
                            <li>
                                1. cena v Národní klavírní soutěži základních
                                hudebních škol (1969) v tehdejším Československu
                            </li>
                            <li>
                                finalistka soutěže o Cenu Beethovenova Hradce
                                (mezinárodni edice, 1972){" "}
                            </li>
                            <li>
                                laureátka téže soutěže v letech 1976 a 1982 (3.
                                cena a Cena za nejlepší interpretaci soudobé
                                skladby){" "}
                            </li>
                            <li>
                                v národní soutěži třikrát 1. cena v Akademické
                                klavírní soutěži studentů JAMU Brno
                            </li>
                        </ul>
                        <p>
                            Absolvovala brněnskou konzervatoř (1978, třida prof.
                            Dr. Jany Soharove) a JAMU v Brně v&nbsp;roce 1982
                            pod vedením doc. Inessy Janíčkové. Během
                            následujících deseti let zastávala funkci
                            korepetitora baletu v&nbsp;Národním divadle
                            v&nbsp;Brně, pracovala jako odborná asistentka na
                            katedře operního zpěvu a katedře smyčcových nástrojů
                            na JAMU Brno a realizovala sólové i komorní
                            recitály, v&nbsp;nichž převažovala díla Vitězslava
                            Nováka.
                        </p>
                        <p>
                            Od roku 1992 pokračuje ve své umělecké činnosti ve
                            Španělsku (Galicie) a Portugalsku, kde působí jako
                            pedagog a klavírní doprovazeč na hudebních školách a
                            konzervatořích. Mezi lety 2010–2019 byla odbornou
                            asistentkou na hudebních katedrách Univerzit
                            v&nbsp;Braze a Evoře a na Vysoké hudební škole
                            v&nbsp;Portu.
                        </p>
                        <p>
                            Ingrid účinkovala na mezinárodních hudebních
                            festivalech (Hudební podzim Brno, The Prague Horn,
                            Guimarães Allegro, Festival Internacional Primavera
                            de Viseu, Mezinárodní festival Atlantic Coast, CCB
                            Lisabon Classic Summer). Jako sólistka, komorní
                            hráčka a lektorka mistrovských klavírních kurzů a
                            workshopů se představila v České republice,
                            Španělsku, Portugalsku, Německu, Řecku, Švédsku a
                            Brazílii (12 recitálů v 5 státech).
                        </p>
                        <p>
                            Natočila CD Metamorphoses (2013) a Quasi Una
                            Fantasia (2019) s&nbsp;významným portugalským
                            saxofonistou Luisem Ribeiro a editovala CD Piano
                            kaleidoscope (vyber z&nbsp;live nahrávek sólových
                            klavírních recitálů, 2018). Byla členkou poroty na
                            Mezinárodní klavírní soutěži ProBohemia Ostrava
                            (2017,2018) a Mezinárodní klavírní soutěži
                            Vítězslava Nováka Kamenice nad Lipou (2016, 2017).
                        </p>
                        <p>
                            V současnosti působí jako klavírní doprovazečka na
                            státní hudební konzervatoři Francisco Rosado
                            v&nbsp;Loulé (Algarve, Portugalsko).{" "}
                        </p>
                        <br />
                        <h1>Ingrid Sotolářová / english</h1>
                        <p>
                            A pianist and piano teacher of Czechoslovakian
                            origin, she initiated her musical studies at the age
                            of 6. Having emerged as a winner in various piano
                            competitions in her homeland, she completed her
                            piano training at the Janacek Academy of Musical
                            Arts (JAMU) in Brno, Czech Republic, under the
                            tutelage of the distinguished pedagogue Inessa
                            Janickova, a graduate from St. Petersburg.
                            Subsequently, she commenced her career as a
                            rehearsal pianist at the National Theater of Brno
                            and served as a Guest Assistant at JAMU Brno,
                            specializing in stringed instruments and opera song.
                        </p>
                        <p>
                            In 1992, she established her residence initially in
                            Spain (Galicia, Vigo) and later in Portugal.
                            Alongside her roles as a piano teacher and
                            accompanying pianist in various conservatories and
                            professional music schools, she pursued a soloist
                            career, delivering solo and chamber recitals at
                            international festivals such as the Atlantic Coast
                            Festival, Verão Clássico, Guimarães Allegro, The
                            Prague Horn, Autumn Brno, and Spring of Viseu.
                            Regularly conducting recitals, masterclasses, and
                            interpretation workshops in Spain, Greece, Brazil,
                            Portugal, Sweden, and the Czech Republic, she held
                            the position of Guest Assistant at the University of
                            Minho (Braga), ESMAE (Escola Superior de Música e
                            Artes escénicas) in Porto, and the Escola das Artes
                            of the University of Évora between 2010 and 2019.
                        </p>
                        <p>
                            Presently, she serves as a teacher and accompanist
                            pianist at the Conservatório de Música de Loulé –
                            Francisco Rosado in Portugal.
                        </p>
                    </div>
                    <div className="px-5 lg:mx-10 mt-10 lg:mt-0">
                        <Koncert {...props} />
                    </div>
                </div>
            </div>
        </>
    );
};
