import { ModalLayout } from "../layout/modal";

export const Brno = () => {
    return (
        <>
            <ModalLayout title={"Program Brno"}>
                <div className="prose">
                    <h2>GiArt Duo – Souběžné cesty (Viajes paralelos)</h2>
                    <p>
                        Recital <br />
                        Gabriel Lezcano (Španělsko Švédsko), zpěv
                        <br />
                        Ingrid Sotolarova (Česká republika /Portugalsko), klavír
                    </p>
                    <h3>Program Brno:</h3>
                    <ul>
                        <li>
                            <strong>B. Smetana (1824 – 1884):</strong> Polka a
                            moll (České tance, I/2)
                        </li>
                        <li>
                            <strong>A. Dvořák (1841 – 1904):</strong> Rings ist
                            der Wald so stumm und still - Als die alte Mutter
                            (Cikánské melodie, op.55)
                        </li>
                        <li>
                            <strong>F. Obradors (1897 – 1945):</strong>{" "}
                            Canciones Clásicas españolas: Corazón, porqué
                            pasáis? - Del cabello más sutil
                        </li>
                        <li>
                            <strong>M. de Falla (1876 – 1946):</strong> Danza
                            Ritual del fuego (Amor Brujo)
                        </li>
                        <li>
                            <strong>M. Adalid (1826 – 1881):</strong> Cantares
                            viejos y nuevos de Galicia: Soedades - Non te quero
                            por bonita
                        </li>
                        <li>
                            <strong>J. Montes (1840-1899):</strong> Negra Sombra
                        </li>
                        <li>
                            <strong>M. Falla (1876 – 1946):</strong> Primera
                            dança de “La vida breve”
                        </li>
                        <li>
                            <strong>M. Falla (1876 – 1946):</strong> 7 canciones
                            populares de España
                        </li>
                    </ul>
                </div>
            </ModalLayout>
        </>
    );
};
