import axios from 'axios';

const sign = require('jwt-encode');
const secret = 'hbGciOiJxNTE2MjM5MIUzI1NiIsInR5cCI6ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaNTY3fyWF0IjoDIew56y';
const data = {
  name: 'Sotolarova',
};
const jwt = sign(data, secret); 
let url
if(process.env.NODE_ENV==='development') {
  url = 'http://localhost/cissus/public/'
} else {
  url = 'https://koncert.cissus.cz/admin/public/'
}

const axiosInstance = axios.create({
  // withCredentials: true,
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    "Authorization" : `Bearer ` + jwt
  }
});

export default axiosInstance;