import React from "react";
import { Koncert } from "../Koncert";
import { NavLink } from "react-router-dom";

import giart from "../../assets/giart.jpg";
//import { NavLink } from "react-router-dom";

export default function Home(props) {
    return (
        <>
            <div className="max-w-7xl mx-auto p-8 lg:p-10">
                <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 ">
                    <Koncert {...props} />
                </div>
                <div className="grid lg:grid-cols-2 gap-8">
                    <div className=" lg:pr-5 prose text-gray-700">
                        <p className="text-red-600 font-bold text-3xl">
                            GiArt Duo
                        </p>
                        <p>
                            GiArt Duo je skupina, kterou tvoří španělský tenor
                            Gabriel Lezcano a česká klavíristka Ingrid
                            Sotolářová. „Po dlouholeté spolupráci na různých
                            projektech jsme se rozhodli vytvořit stabilní
                            formaci a přijímat hudební i osobní výzvy, které se
                            nám nabízí. - Možnosti spojení zpěvu a klavíru
                            umožňují našemu Duu oslovit různá hudební období,
                            skladatele a žánry (Lied, Zarzuela, Opera, komorní
                            hudba...).
                        </p>
                        <p>
                            {" "}
                            Během deseti let existence Dua jsme opakovaně
                            vystupovali na různých scénách v Galicii,
                            Katalánsku, Portugalsku, Řecku a Švédsku.“
                        </p>
                        <p>
                            <NavLink to={"/napsali-o-nas"}>
                                Napsali o nás
                            </NavLink>
                        </p>
                        <p className="text-red-600 font-bold text-3xl">
                            GiArt Duo / english
                        </p>
                        <p>
                            Giart Duo consists of Gabriel Lezcano, a tenor of
                            Galician origin who has been residing in Sweden
                            since 2017, and Ingrid Sotolarova, a Czech-born
                            pianist based in Portugal since 2007. Gabriel has a
                            broad artistic background, serving as both a soloist
                            and the director of the choir he founded, Kultur på
                            spanska kören. Ingrid is actively engaged in
                            pedagogical and concert activities in Spain and
                            Portugal, offering masterclasses and recitals in
                            various countries. After collaborating on several
                            projects, we decided to establish a stable ensemble
                            to tackle the musical and personal challenges that
                            arise.{" "}
                        </p>{" "}
                        <p>
                            {" "}
                            The combination of voice and piano in a single
                            ensemble enables us to explore different musical
                            periods, composers, and genres, including Lied,
                            Zarzuela, Opera, and chamber music. Together, we
                            have performed on various stages in Galicia,
                            Catalonia, Sweden, Greece, Portugal, and beyond.
                        </p>
                    </div>
                    <div>
                        <img
                            src={giart}
                            alt="GiArt Duo"
                            className="my-5 mb-8"
                        />
                        <iframe
                            className="w-full min-h-[300px] aspect-video"
                            src="https://www.youtube.com/embed/7IuPFrfIZOU?si=nqJFW4SPUptJLdEH"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                        ></iframe>
                    </div>
                </div>
            </div>
        </>
    );
}
