import { ModalLayout } from "../layout/modal";

export const Wien = () => {
    return (
        <>
            <ModalLayout title={"Program Wien"}>
                <div className="prose">
                    <h2>GiArt Duo – Parallelfahrten (Viajes paralelos)</h2>
                    <p>
                        Recital <br />
                        Gabriel Lezcano (Spanien/Sweden), tenor
                        <br />
                        Ingrid Sotolarova (Tchechien/Portugal), piano
                        <br />
                        <br />
                        <strong>24.03.2024, Galerie am Roten Hof, Wien</strong>
                    </p>
                    <h3>Program Praha:</h3>
                    <ul>
                        <li>
                            <strong>B. Smetana (1824 – 1884):</strong> Polka a
                            moll (Tschechische Tänze, I/2)
                        </li>
                        <li>
                            <strong>
                                A. Dvořák (1841 – 1904) Zigeunermelodien:
                            </strong>
                            - Rings ist der Wald so stumm und still - Als die
                            alte Mutter
                        </li>
                        <li>
                            <strong>F. Obradors (1897 – 1945):</strong>{" "}
                            Canciones Clásicas españolas: - Corazón, porqué
                            pasáis? - Del cabello más sutil
                        </li>
                        <li>
                            <strong>M. de Falla (1876 – 1946):</strong> Danza
                            Ritual del fuego (Amor Brujo)
                        </li>
                        <li>
                            <strong>M. Adalid (1826 – 1881):</strong> Cantares
                            viejos y nuevos de Galicia: - Soedades - Non te
                            quero por bonita
                        </li>
                        <li>
                            <strong>J. Montes (1840-1899):</strong> Negra Sombra
                        </li>
                        <li>
                            <strong>M. Falla (1876 – 1946):</strong> Primera
                            dança de “La vida breve”
                        </li>
                        <li>
                            <strong>M. Falla (1876 – 1946):</strong> 7 canciones
                            populares de España
                        </li>
                    </ul>
                </div>
            </ModalLayout>
        </>
    );
};
