import { ChevronLeftIcon } from "@heroicons/react/solid";
import { NavLink } from "react-router-dom";
import { Koncert } from "./Koncert";

export const Gabriel = (props) => {
    return (
        <>
            <div className="max-w-7xl mx-auto p-8 lg:p-10">
                <NavLink
                    to={"/"}
                    className="p-3  px-5 bg-gray-200 rounded-md inline-flex mb-5 items-center"
                >
                    <ChevronLeftIcon className="w-5 h-5" /> Zpět
                </NavLink>
                <div className="grid lg:grid-cols-2">
                    <div className=" prose prose-blue  ">
                        <h1>Gabriel Lezcano</h1>
                        <p>
                            Gabriel Lezcano je španělský multižánrový zpěvák,
                            působící od r. 2017 ve švédském Stockholmu.Po studiu
                            hispánské filologie na Univerzitě Vigo (Galicie,
                            Španělsko) a rockových a folkových začátcích našel
                            hluboký vztah k opernímu a písnovému repertoáru a
                            začal jej studovat nejprve na konzervatoři ve Vigu a
                            posléze soukromě v Barceloně, mezi jinými s Fernando
                            Balboa, Vicente Esteve, Dolors Aldea, Pilar
                            Moráguez, Jaume Aragall, Iwa Sorenson. V Katalánii
                            byl také často obsazován do rolí v zarzulelách,
                            operách i muzikálech: "Don Manolito", "La del manojo
                            de rosas", "Los gavilanes", "La galeota", "El duo de
                            la Africana", "Katiuska", "La viejecita", "Tosca"
                            (Spoletta), Marina (Capitán Alberto)... y musicales
                            como "El Mikado", "Dos mexicanos de cuidado", "La
                            taverna d´en Mallol",mezi jinými.
                        </p>
                        <p>
                            Působí v několika uměleckých ansámblech, z nichž
                            většinu založil: GP Musics s klavíristou Pablo
                            Olivares , Galiciskt och Tango s argentinským
                            akordeonistou působícím v Malmö , GiArt Duo s Ingrid
                            Sotolarovou. Za 10 let své existence (2013 – dosud),
                            GiArt Duo realizovalo opakovaně recitaly ve
                            Španělsku , Portugalsku, Řecku a Švédsku.
                        </p>
                        <p>
                            Gabriel je dirigentem pěti pěveckých sborů ve
                            Stockholmu, autorem, producentem i jedním z hlavních
                            představitelů zarzuely La lírica de bolsillo
                            (Kapesní zarzuela), prováděné ve Stockholmu již v
                            mnoha reprízách. Je také ředitelem kulturní asociace
                            Kultur på spanska ve Stockholmu.
                        </p>
                        <br />
                        <h1>Gabriel Lezcano / english</h1>
                        <p>
                            A Galician tenor, currently residing in Stockholm,
                            Sweden since 2017, Gabriel has adeptly balanced his
                            studies in Hispanic Philology at the University of
                            Vigo with operatic training at the Coppelia
                            Conservatory in Vigo. Later, he pursued private
                            lessons in Barcelona with esteemed instructors such
                            as Fernando Balboa, Vicente Esteve, Dolors Aldea,
                            Pilar Moráguez, Jaume Aragall, Iwa Sorenson, among
                            others.
                        </p>
                        <p>
                            His journey led him to Catalonia, where he assumed
                            leading roles in zarzuelas, operas, and musicals,
                            showcasing his talent in productions like Don
                            Manolito, La del manojo de rosa, Los gavilanes, La
                            galeota, El dúo de la africana, Katiuska, La
                            viejecita, Tosca (Spoletta), Marina (Captain
                            Alberto), and musicals such as El Mikado, Dos
                            mexicanos de cuidado, and La taverna d'en Mallol,
                            among others.
                        </p>
                        <p>
                            Presently, Gabriel is an integral part of several
                            musical groups, including GP Musics (collaborating
                            with pianist Pablo Olivares), Galiciskt och Tango
                            (partnering with Argentine accordionist Alan
                            Haksten), and the GiArt Duo, alongside pianist
                            Ingrid Sotolarova, with whom he has been performing
                            recitals in Spain, Portugal, Greece, and Sweden for
                            the past decade (2013 to the present). Beyond his
                            vocal pursuits, Gabriel serves as the director of
                            five choirs in Stockholm and holds the position of
                            artistic director for the company La lírica de
                            bolsillo in Stockholm. Additionally, he directs the
                            cultural association Kultur på spanska.
                        </p>
                    </div>

                    <div className="px-5 lg:mx-10 mt-10 lg:mt-0">
                        <Koncert {...props} />
                    </div>
                </div>
            </div>
        </>
    );
};
