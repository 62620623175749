import React  from "react";  
import {Transition} from "@headlessui/react";  
 
 
export const ModalLayout = (props) => {
    return (
        <>
        <div className="  rounded-b-lg   " >

            <div className="  p-5 lg:p-8 bg-gray-100 border-b  rounded-t-lg ">
                    <div className=" lg:grid lg:grid-cols-2 items-center justify-between flex-wrap sm:flex-nowrap">
                         
                        <div className="  flex-shrink-0 ">
                            <h3 className="text-lg leading-6 font-medium text-gray-600">
                                {props.title}
                            </h3>
                            
                        </div>
                    </div>
                </div>
                
                <div className=" p-5 lg:p-8 align-bottom rounded-b-lg  text-left transform transition-all  sm:align-middle sm:w-full  bg-white ">
                    {props.children}
                </div>
                
            </div>
            
        </>
    )
}

export const Modal = (props) => { 

    let Component = props.modalContent?.component
    let dynamicProps = props.modalContent?.params 
    const modalClose = props.modalClose 
    const setModalIsOpen = props.setModalIsOpen 
    const modalIsOpen = props.modalIsOpen 

    function close() {
        modalClose();
        setModalIsOpen(false);
    }
      
    return (
        <>
            <Transition show={modalIsOpen} className="fixed z-50 inset-0  overflow-y-auto backdrop-filter backdrop-blur-sm h-full">
            <div className="flex flex-wrap  items-end overflow-y-auto h-full   pb-20 text-center sm:block sm:p-0  ">
             
                <Transition.Child
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                    enter="ease-out duration-50"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-50"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed top-0 bottom-0 inset-0 bg-black opacity-20 h-full z-10 " onClick={() => close()} />
                </Transition.Child>
                <Transition.Child>
               
                </Transition.Child> 
                <span className={"hidden sm:inline-block sm:align-middle " + (props.electron ? "sm:h-screen-electron" : " ")} aria-hidden="true">&#8203;</span>
                 
                            
                <Transition.Child
                    className="inline-block align-bottom  rounded-lg mx-2 text-left overflow-hidden  transform transition-all sm:align-top sm:max-w-4xl w-full "
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                    enter="ease-out duration-50"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-50"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    
                     <div className="absolute top-2 right-2 mx-auto  z-50" onClick={() => close()}>
                        <button className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200 ring-white ring-inset ring-2  text-gray-500 bg-gray-100">
                            <span className="sr-only">Close sidebar</span>
                        
                            <svg className="h-6 w-6 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                        
                    <div className=" align-bottom  text-left  transform transition-all overflow-hidden sm:align-middle sm:w-full  mt-16  border border-gray-400 bg-white rounded-lg lg:mb-10 ">
                            {Component &&
                                <Component  
                                {...dynamicProps}
                                {...props}
                                />  
                            }
                        
                    </div>
                </Transition.Child>
            </div>
        </Transition>
        </>
    );
}

