import React, { useState } from "react";
import { Modal } from "./layout/modal";
import { default as ingrid } from "../assets/ingrid2.jpg";
import { default as gabriel } from "../assets/gabriel.png";
import { NavLink } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/solid";

// import Ca from "../images/ca.jpg";
// import Bk from "../images/blansko.jpg";
import Asociace from "../images/asociace-stockholm.jpg";
import Zatrojku from "../images/zatrojku.jpg";
import Atrium from "../images/atrium.jpg";
import Galerie from "../images/galerie.jpg";
import Tic from "../images/tic.jpg";
import Listy from "../images/listy.jpg";

const mainTitle = " ";

export default function Layout(props) {
    const Component = props.component;
    const [title, setTitle] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState(false);
    const [eanActive, setEanActive] = useState(false);

    function modalClose() {
        setModalIsOpen(false);
        setTimeout(() => {
            setModalContent(false);
        }, 600);
    }

    return (
        <>
            <main className="  w-full overflow-y-auto   ">
                <div className=" bg-black bg-contain lg:bg-30% bg-piano bg-right bg-no-repeat p-5 relative ">
                    <div className="absolute bg-gradient-to-r from-red-900 via-red-800 to-transparent left-0 right-0 top-0 bottom-0 w-full h-full " />
                    <div className="relative max-w-7xl mx-auto ">
                        <NavLink
                            to={"/"}
                            className="block pt-2  text-4xl sm:text-6xl text-red-200  mx-auto font-light "
                        >
                            Recitál (zpěv a klavír)
                        </NavLink>
                        <NavLink
                            to={"/"}
                            className="block  pb-3 sm:pb-6 text-lg sm:text-2xl text-red-200  mx-auto font-light "
                        >
                            Souběžné cesty / Viajes paralelos - GiArt Duo
                        </NavLink>
                        <div className=" grid gap-2 md:grid-cols-2 text-gray-300">
                            <NavLink
                                to={"/ingrid-sotolarova"}
                                className="flex w-auto items-center space-x-4 lg:my-3 bg-black bg-opacity-20 justify-between hover:bg-opacity-30 rounded-md p-3 "
                            >
                                <div className="flex items-center space-x-4">
                                    <img
                                        src={ingrid}
                                        className="rounded-full w-12 lg:w-16 border "
                                        alt="Ingrid Sotolářová"
                                    />

                                    <div className=" gap-2 ">
                                        <div className="text-xl md:text-3xl font-bold shadow-sm">
                                            Ingrid Sotolářová
                                        </div>
                                        <div className="flex gap-2 justify-start">
                                            <div className="  items-center space-x-2 text-sm flex">
                                                ČR / Portugalsko
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ChevronRightIcon className="w-10 h-10" />
                            </NavLink>
                            <NavLink
                                to={"/gabriel-lezcano"}
                                className="flex w-auto items-center  lg:my-3 bg-black bg-opacity-20  hover:bg-opacity-30 justify-between rounded-md p-3 "
                            >
                                <div className="flex items-center space-x-4">
                                    <img
                                        src={gabriel}
                                        className="rounded-full w-12 lg:w-16 border "
                                        alt="Gabriel Lezcano"
                                    />

                                    <div className=" justify-center gap-2 ">
                                        <div className="text-xl md:text-3xl font-bold ">
                                            Gabriel Lezcano
                                        </div>
                                        <div className="flex gap-2 justify-start">
                                            <div className="  items-center space-x-2 text-sm flex">
                                                Španělsko / Švédsko
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ChevronRightIcon className="w-10 h-10" />
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto ">
                    <Component
                        {...props}
                        modalIsOpen={modalIsOpen}
                        setModalIsOpen={setModalIsOpen}
                        modalClose={modalClose}
                        modalContent={modalContent}
                        setModalContent={setModalContent}
                        setTitle={setTitle}
                        mainTitle={mainTitle}
                        title={title}
                    />

                    <div className="mx-auto max-w-6xl text-center">
                        <div className=" w-full justify-center  py-5 bg-white text-gray-400 text-sm  max-w-7xl mx-auto ">
                            <div className="grid grid-cols-3 px-3 space-x-6 gap-10  md:grid-cols-4 lg:flex  w-full justify-center ">
                                {/* <div className="flex items-center justify-center">
                                    <img
                                        alt="Muzeum Blanenska"
                                        src={Bk}
                                        className="w-32"
                                    />
                                </div>
                                <div className="flex items-center justify-center">
                                    <img
                                        alt="Klub moravských skladatelů"
                                        src={Kms}
                                        className="w-16"
                                    />
                                </div> */}
                                <div className="flex items-center justify-center">
                                    <img
                                        alt="Asociace Stockholm"
                                        src={Asociace}
                                        className="w-44"
                                    />
                                </div>
                                <div className="flex items-center justify-center">
                                    <img
                                        alt="Za Trojku"
                                        src={Zatrojku}
                                        className=" max-h-16"
                                    />
                                </div>
                                <div className="flex items-center justify-center">
                                    <img
                                        alt="Atrium na Žižkově"
                                        src={Atrium}
                                        className="w-44"
                                    />
                                </div>
                                <div className="flex items-center justify-center">
                                    <img
                                        alt="Galerie am Roten Hof"
                                        src={Galerie}
                                        className="w-44"
                                    />
                                </div>
                                <div className="flex items-center justify-center">
                                    <img
                                        alt="TIC Brno"
                                        src={Tic}
                                        className="w-44"
                                    />
                                </div>
                                <div className="flex items-center justify-center">
                                    <a
                                        href="https://viden-vsl.at/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            alt="Vídeňské svobodné listy"
                                            src={Listy}
                                            className="w-44"
                                        />
                                    </a>
                                </div>
                                {/* <div className="flex items-center justify-center"><a href="https://www.maxeco.info/" target="_blank"><StaticImage src={'../images/maxeco.png'} className="w-20" /></a></div> */}
                            </div>
                        </div>
                    </div>

                    <div className="p-10 text-center text-gray-700 border-t pt-5 mt-5 text-xs ">
                        <p>
                            Vstupenky zajišťuje: CISSUS PROmotion s.r.o.,
                            Francouzská 284/94, 10100 Praha 10, IČ 02896737
                        </p>
                        <a
                            href="mailto:vstupenky@cissus.cz"
                            className="underline text-red-700   hover:no-underline"
                        >
                            vstupenky@cissus.cz
                        </a>
                    </div>
                </div>
            </main>
            <Modal
                {...props}
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                modalClose={modalClose}
                modalContent={modalContent}
                setModalContent={setModalContent}
                eanActive={eanActive}
                setEanActive={setEanActive}
            />
        </>
    );
}
