import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { ChevronRightIcon, MinusIcon, PlusIcon } from "@heroicons/react/solid";
import axiosInstance from "../client/index.js";
import { Loading } from "./layout/Loading.js";
import { Error } from "./form/Error";

export const Order = (props) => {
    const item = props.item;

    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(1);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        control,
    } = useForm({
        mode: "onBlur",
    });

    const [donation, setDonation] = useState(0);
    const [custom, setCustom] = useState(false);
    const prispevky = [0, 50, 100, 250];

    const change = useWatch({
        control,
        name: "donation",
        defaultValue: donation,
    });

    const checkCount = useWatch({
        control,
        name: "count",
        defaultValue: count,
    });

    useEffect(() => {
        setDonation(parseInt(change));
    }, [change]);

    useEffect(() => {
        checkCount && setCount(parseInt(checkCount));
    }, [checkCount]);

    useEffect(
        () => {
            setValue("count", count);
            setValue("donation", donation);
        },
        // eslint-disable-next-line
        [count, donation]
    );

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            const res = await axiosInstance.post("wizard/orderSend/", {
                data: data,
                event: item,
            });
            if (res.data) {
                window.location = "/platba/" + res.data.order_number + "/";
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };

    function donate(amount) {
        setDonation(amount);
        setCustom(false);
    }

    return (
        <>
            <div className="p-10 ">
                <div className="grid grid-cols-2 pb-4 border-b ">
                    <div className="text-2xl font-bold">{item.city}</div>
                    <div className="flex items-center justify-end space-x-2">
                        <div>{item.date}</div>
                    </div>
                </div>

                <form
                    key={1}
                    onSubmit={handleSubmit(onSubmit)}
                    className={"  mt-3 "}
                >
                    <div className="grid grid-cols-2 gap-2 lg:gap-4 ">
                        <div>
                            <label
                                htmlFor="first_name"
                                className="block text-sm  text-gray-700 font-bold"
                            >
                                Jméno{" "}
                                <span className="text-lg inline-flex items-center text-red-600">
                                    *
                                </span>
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    id="first_name"
                                    {...register("fname", { required: true })}
                                    className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors?.fname && (
                                    <Error text="Vyplňte jméno" />
                                )}
                            </div>
                        </div>

                        <div>
                            <label
                                htmlFor="last_name"
                                className="block text-sm font-bold text-gray-700"
                            >
                                Příjmení{" "}
                                <span className="text-lg inline-flex items-center text-red-600">
                                    *
                                </span>
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    id="last_name"
                                    {...register("surname", { required: true })}
                                    className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors?.surname && (
                                    <Error text="Vyplňte příjmení" />
                                )}
                            </div>
                        </div>

                        <div className="col-span-2 sm:col-span-1">
                            <label
                                htmlFor="email"
                                className="block text-sm font-bold text-gray-700"
                            >
                                E-mail{" "}
                                <span className="text-lg inline-flex items-center text-red-600">
                                    *
                                </span>
                            </label>
                            <div className="mt-1 relative">
                                <input
                                    id="email"
                                    type="email"
                                    {...register("email", { required: true })}
                                    className={
                                        "shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md "
                                    }
                                />
                                {errors?.email && (
                                    <Error text="Vyplňte e-mail" />
                                )}
                            </div>
                        </div>
                        <div className="">
                            <label
                                htmlFor="tel"
                                className="block text-sm font-bold text-gray-700"
                            >
                                Telefon{" "}
                                <span className="text-lg inline-flex items-center text-red-600">
                                    *
                                </span>
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    id="tel"
                                    {...register("tel", { required: true })}
                                    className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors?.tel && (
                                    <Error text="Vyplňte telefon" />
                                )}
                            </div>
                        </div>

                        <div className="col-span-2">
                            <label
                                htmlFor="note"
                                className="block text-sm  text-gray-700"
                            >
                                Poznámka
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    id="note"
                                    {...register("note")}
                                    className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-2">
                        <label
                            htmlFor="count"
                            className="block text-sm font-bold text-gray-700"
                        >
                            Počet vstupenek{" "}
                            <span className="text-lg inline-flex items-center text-red-600">
                                *
                            </span>
                        </label>
                        <div className="mt-1 flex items-center ">
                            {count > 1 ? (
                                <button
                                    type="button"
                                    onClick={() => setCount(count - 1)}
                                    className="bg-blue-600 h-12 flex items-center rounded-l-md p-2 hover:bg-blue-700"
                                >
                                    <MinusIcon className="w-4 h-4 text-white cursor-pointer" />
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    className="bg-blue-600 h-12 flex items-center rounded-l-md p-2 hover:bg-blue-700"
                                >
                                    <MinusIcon className="w-4 h-4 text-white cursor-pointer" />
                                </button>
                            )}

                            <input
                                type="number"
                                min={1}
                                id="count"
                                {...register("count", { required: true })}
                                defaultValue={count}
                                className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block text-lg w-20  h-12 text-center   border-gray-300 "
                            />
                            <button
                                type="button"
                                className="bg-blue-600 h-12 flex items-center rounded-r-md p-2 hover:bg-blue-700"
                                onClick={() => setCount(count + 1)}
                            >
                                <PlusIcon className="w-4 h-4 text-white " />
                            </button>
                        </div>
                        {errors?.count && (
                            <Error text="Vyplňte počet vstupenek" />
                        )}
                    </div>
                    <div className="mt-4">
                        <label
                            htmlFor="count"
                            className="block text-sm text-gray-700"
                        >
                            Dobrovolný příspěvek
                        </label>

                        <div className="mt-1 ">
                            {prispevky.map((prispevek, prispevekIdx) => (
                                <button
                                    type="button"
                                    onClick={() => donate(prispevek)}
                                    key={prispevekIdx}
                                    className={
                                        "button mb-2 inline-flex mr-2  rounded-md p-2 px-3  " +
                                        (donation === prispevek && !custom
                                            ? "bg-blue-600 hover:bg-blue-700 text-white"
                                            : "bg-gray-200 hover:bg-gray-300")
                                    }
                                >
                                    {parseInt(prispevek).toLocaleString("cs")}{" "}
                                    Kč
                                </button>
                            ))}
                            <button
                                onClick={() => setCustom(true)}
                                type="button"
                                className={
                                    "button inline-flex mb-2  rounded-md p-2 px-3 " +
                                    (custom
                                        ? "bg-blue-600 hover:bg-blue-700 text-white"
                                        : "bg-gray-200 hover:bg-gray-300")
                                }
                            >
                                jiná částka
                            </button>
                        </div>
                        <div className={"col-span-2 " + (!custom && "hidden")}>
                            <label
                                htmlFor="note"
                                className="block text-sm  text-gray-700"
                            >
                                Váš příspěvek
                            </label>
                            <div className="mt-1">
                                <input
                                    type="number"
                                    min={0}
                                    {...register("donation")}
                                    defaultValue={donation}
                                    className={
                                        "shadow-sm   focus:ring-sky-500 focus:border-sky-500    w-32  inline text-center rounded-md   border-gray-300 "
                                    }
                                />{" "}
                                Kč
                            </div>
                        </div>

                        <div className="block text-sm text-gray-600 mt-2">
                            Děkujeme, že pomůžete s úhradou nákladů za pronájem
                            sálu a letenky.
                        </div>
                    </div>

                    <div className="text-2xl text-right py-4 ">
                        Celkem:{" "}
                        <span className="font-medium">
                            {parseInt(300 * count + donation).toLocaleString(
                                "cs"
                            )}{" "}
                            Kč
                        </span>
                    </div>

                    <div className="sm:col-span-2 mt-3 flex justify-end">
                        <button className="flex items-center   text-white h-12 text-lg   px-5 py-1 focus:outline-none  space-x-4 bg-lime-600 rounded-md border border-gray-200 hover:bg-lime-700">
                            <span>Odeslat objednávku</span>
                            <ChevronRightIcon className="w-8 " />
                        </button>
                    </div>
                </form>
            </div>
            <Loading loading={loading} />
        </>
    );
};
