import Home from "./components/layout/home.js";
import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Layout from "./components/layout";
import "./App.css";
import Sent from "./components/layout/Sent.js";
import { Koncert } from "./components/Koncert.js";
import { Ingrid } from "./components/Ingrid.js";
import { Gabriel } from "./components/Gabriel.js";
import { Napsali } from "./components/Napsali.js";

function App() {
    return (
        <>
            <BrowserRouter>
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={(props) => (
                            <Layout {...props} component={Home} />
                        )}
                    />
                    <Route
                        path="/koncert/:mesto"
                        render={(props) => (
                            <Layout {...props} component={Koncert} />
                        )}
                    />
                    <Route
                        path="/napsali-o-nas"
                        render={(props) => (
                            <Layout {...props} component={Napsali} />
                        )}
                    />
                    <Route
                        path="/prihlaska-odeslana"
                        render={(props) => (
                            <Layout {...props} component={Sent} />
                        )}
                    />
                    <Route
                        path="/ingrid-sotolarova"
                        render={(props) => (
                            <Layout {...props} component={Ingrid} />
                        )}
                    />
                    <Route
                        path="/gabriel-lezcano"
                        render={(props) => (
                            <Layout {...props} component={Gabriel} />
                        )}
                    />
                </Switch>
            </BrowserRouter>
        </>
    );
}

export default App;
