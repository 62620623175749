import { ChevronLeftIcon } from "@heroicons/react/solid";
import { NavLink } from "react-router-dom";

import { Koncert } from "./Koncert";

export const Napsali = (props) => {
    return (
        <>
            <div className="max-w-7xl mx-auto p-8 lg:p-10">
                <NavLink
                    to={"/"}
                    className="p-3  px-5 bg-gray-200 rounded-md inline-flex mb-5 items-center"
                >
                    <ChevronLeftIcon className="w-5 h-5" /> Zpět
                </NavLink>
                <div className="grid lg:grid-cols-2">
                    <div className=" prose prose-blue  ">
                        <h1>Napsali o nás</h1>
                        <p>
                            {" "}
                            “Dne 23. dubna 2023 se v Selånger Church ve švédském
                            Sundsvallu uskutečnil koncert tenoristy Gabriela
                            Lezcana (Španělsko/Švédsko) a klavíristky Ingrid
                            Sotolarové (ČR/Portugalsko), známých jako GiArt Duo.
                            Tito hudebníci nás potěšili atraktivním a zajímavým
                            programem. Koncert byl opravdu šťastným okamžikem
                            pro ty, kteří jej slyšeli. Gabriel ovládá hlasovou
                            techniku na vysoké úrovni a navíc své písně
                            reprezentuje s výraznou výrazovou kapacitou. Ingrid
                            je zdatná klavíristka, která kromě komorní hudby
                            zahrála i několik sólových skladeb; doprovázela s
                            talentem a pozorností a poskytovala krásné nuance
                            tenorovému partu. Byl to opravdu skvělý koncert a
                            ti, kteří mohli být přítomni, si přejí, aby se
                            GiArtDuo vrátilo.“
                        </p>
                        <p className="font-bold">
                            Larisa Jigarova, manažerka koncertního cyklu
                            Selånger kyrka v Sundsvallu, Švédsko
                        </p>
                        <p className="font-bold">
                            <br />
                        </p>

                        <p>
                            Komorní duo Giartdúo (tenorista Gabriel Lezcano a
                            klavíristka Ingrid Sotolarova) vystoupilo na III.
                            Komorním koncertu AWG (Asociace Wagner Galicia), 7.
                            listopadu 2015, na profesionální hudební
                            konzervatoři v La Coruña, s programem prolínajícím
                            Goethovu poezii (Kennst du das Land jako vůdčí nit
                            textu, procházející díly německého romantismu -
                            Beethoven, Liszt, Shubert, Bramhs, Schumann, H.
                            Wolf, R. Wagner). Byl to brilantní interpretační
                            výkon této hudební dvojice, která vyčerpávajícím
                            způsobem pracuje na kvalitě textu i nastudování
                            partitury a podrobně i citlivě přistupuje ke stylu
                            pozdního středoevropského romantismu.
                        </p>
                        <p className="font-bold">
                            Carlos Ríos, ředitel Wagnerian Association of
                            Galicia
                        </p>
                    </div>
                    <div className="px-5 lg:mx-10 mt-10 lg:mt-0">
                        <Koncert {...props} />
                    </div>
                </div>
            </div>
        </>
    );
};
